<template>
  <div class="mt-4 w-11/12 md:w-8/12 text-left font-proximaMedium text-sm">
    <h5>Notes:</h5>
    <textarea
      @input="changeDignostic"
      placeholder="you can enter extra information about your pet here, which will be private (viewable only by primary owner and co-owners)"
      class="mt-2 w-full h-20 p-2 text-sm rounded-sm font-proximaLight"
    ></textarea>
    <div class="mt-4 flex justify-center">
      <ul class="w-11/12 md:w-6/12 flex justify-start flex-wrap">
        <li
          v-for="(data, index) in unverifiedHealthStatus"
          :key="index"
          class="w-max mr-2 mt-2 text-white bg-primary rounded-md px-3 py-2 relative"
        >
          {{ data }}
          <div
            class="bg-white rounded-full absolute -right-1 -top-1 cursor-pointer"
          >
            <TrashIcon iconColor="#0AC0CB" @click.native="deleteDiag(data)" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import TrashIcon from "@/components/icons/TrashIcon.vue";

export default {
  props: {
    unverifiedHealthStatus: [],
  },
  components: {
    TrashIcon,
  },
  data() {
    return {
      // { id: "1", name: "rabbit-directed aggression" },
    };
  },
  methods: {
    changeDignostic(e) {
      this.$emit("changeDignostic", e.target.value);
    },
    deleteDiag(val) {
      this.$emit("deleteDiag", val);
    },
  },
};
</script>

<style></style>
