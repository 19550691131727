<template>
  <div class="my-6">
    <DogSection />
    <NavMenuSecondary  />
  </div>
</template>

<script>
import DogSection from "../components/newpetrecord/dog/DogSection.vue";
import NavMenuSecondary from "../components/navigationmenu/secondary/NavMenuSecondary.vue"

export default {
  components: {
    DogSection,
    NavMenuSecondary
  },
};
</script>